import React from "react";
import BlockContent from "../sanity/block-content";
import styles from "./textSection.module.css";
import { ContainerComponent } from "../container";

function TextSection(props) {
  const { heading, label, tagline } = props;

  return (
    <ContainerComponent>
      <section className={styles.article}>
      <div style={{ backgroundColor: (props.bgcolor && props.bgcolor.hex) || "transparent", padding: (props.padding && props.padding) || "0px" }}>

        <div className={styles.label}>{label}</div>
        <h2 className={styles.heading}>{heading}</h2>
        <BlockContent blocks={tagline} />
        </div>
      </section>
    </ContainerComponent>
  );
}

export default TextSection;
