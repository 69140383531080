import React from "react";
import BlockContent from "../sanity/block-content";
import CTALink from "../CTALink";
import styles from "./heroSimple.module.css";
import { ContainerComponent } from "../container";

function heroSimple(props) {
  const {tagline} = props
  return (
    <div style={{ backgroundColor: (props.bgcolor && props.bgcolor.hex) || "transparent" }}>
      <div style={{ color: (props.textcolor && props.textcolor.hex) || "black" }}>
        <ContainerComponent>
          <section className={styles.wrapper}>
          <p className={styles.leftColLabel}>{props.label}</p>
            <div className={styles.heroDescription}>
              <BlockContent blocks={tagline} />
            </div>
          </section>
        </ContainerComponent>
      </div>
    </div>
  );
}

export default heroSimple;


/* 
            <div className={styles.cta}>
              {(props.ctas || []).map((c, i) => (
                <CTALink key={`cta_${i}`} {...c} buttonActionClass={styles.button}/>
              ))}
            </div>
            */