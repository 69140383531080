import React from "react";
import BlockContent from "../sanity/block-content";
import styles from "./peopleGrid.module.css";
import { ContainerComponent } from "../container";

import { imageUrlFor } from "../../lib/image-url";
import { buildImageObj } from "../../lib/helpers";


function PeopleGrid(props) {

  /*const imagesWithCaption = props.authors.image.map((i) => {
    return (
      <figure style={{ margin: (props.margin && props.margin) || "0"}}>
        <img
          src={imageUrlFor(buildImageObj(i))
            .width(500)
            .height(300)
            .url()}
          alt={i.alt}
          key={i._id}
          loading={"lazy"}
          style={{
            padding: "35px",
            borderRadius: (props.borderradius && props.borderradius) || "0px",
            backgroundColor: i.asset.metadata.palette.dominant.background,
          }}
        />
      </figure>
    );
  });*/

  const containerVariableStyles = {
    backgroundColor: (props.bgcolor && props.bgcolor.hex) || "transparent",
    color: (props.textcolor && props.textcolor.hex) || "black",
    padding: (props.padding && props.padding) || "0px",
    maxWidth: (props.maxwidth && props.maxwidth) || "1280px",
    margin: (props.margin && props.margin) || "auto",
    borderRadius: (props.borderradius && props.borderradius) || "0px",
    width: "100%",
  };

  return (
    <div style={containerVariableStyles}>
        <ContainerComponent>
          <div>
            <section className={styles.wrapper}>
              <h2>{props.heading}</h2>
              <div style={{ gap: (props.gridgap && props.gridgap) || "15px",  }} className={styles.grid}>{/*imagesWithCaption*/}


          {props.authors.map(({author, _key}) => {
          const authorName = author && author.name
          const authorBio = author && author.bio
          return (
            <div key={_key} className={styles.listItem}>
              <div>
                <div className={styles.avatar}>
                  {author && author.image && author.image.asset && (
                    <img className={styles.img}
                      src={imageUrlFor(buildImageObj(author.image))
                        .width(800)
                        .height(1000)
                        .fit('crop')
                        .url()}
                      alt={author.image.alt}
                    />
                  )}
                </div>
              </div>
              <div>
                <h3 className={styles.name}>{authorName || <em>Missing name</em>}</h3>
              </div>
              <div className={styles.description}>
                <BlockContent blocks={authorBio} />
              </div>
            </div>
          )
        })}
        </div>

            </section>
          </div>
        </ContainerComponent>
      </div>
  );
}

export default PeopleGrid;
