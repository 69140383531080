import React from "react";
import BlockContent from "../sanity/block-content";
import CTALink from "../CTALink";
import styles from "./simpleGrid.module.css";
import { ContainerComponent } from "../container";

function SimpleGrid(props) {
  const containerVariableStyles = {
    backgroundColor: (props.bgcolor && props.bgcolor.hex) || "transparent",
    color: (props.textcolor && props.textcolor.hex) || "black",
  };
  const itemVariableStyles = {
    padding: (props.padding && props.padding) || "25px",
    borderRadius: (props.borderradius && props.borderradius) || "0px",
  };
  return (
    <div style={containerVariableStyles}>
        <ContainerComponent>
          <section className={styles.wrapper}>
            <h2>{props.label}</h2>
            <div>
            <div className={styles.grid} style={{ gap: (props.gridgap && props.gridgap) || "15px",  }}>
              {props.items.map((item, _key) => {
                const itemName = item && item.name;
                return (
                  <div key={_key} className={styles.gridItem} style={itemVariableStyles}>
                    <div>
                      <h3 className={styles.name}>
                        {itemName || <em>Missing name</em>}
                      </h3>
                    </div>
                    <div className={styles.description}>
                      <BlockContent blocks={item.content} />
                    </div>
                  </div>
                );
              })}
            </div>
            </div>
          </section>
        </ContainerComponent>
      </div>
  );
}

export default SimpleGrid;

/* 
            <div className={styles.cta}>
              {(props.ctas || []).map((c, i) => (
                <CTALink key={`cta_${i}`} {...c} buttonActionClass={styles.button}/>
              ))}
            </div>
            */

/* 
                            <div className={styles.avatar}>
                  {author && author.image && author.image.asset && (
                    <img className={styles.img}
                      src={imageUrlFor(buildImageObj(author.image))
                        .width(800)
                        .height(1000)
                        .fit('crop')
                        .url()}
                      alt={author.image.alt}
                    />
                  )}
                </div>*/
