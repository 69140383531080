import React from "react";
import { Link } from "gatsby";
import Marquee from "react-fast-marquee";
import styles from './marqueeComp.module.css'

export default function MarqueeComp(props) {
  const paragraph = {
    width: props.textWidth,
    /*fontStyle: 'italic',*/
  }
  return (
  <Link to={props.link}>
    <div
      className={styles.link}
    >
      <Marquee
        className={styles.marqueeWrapper}
        gradient={false}
        pauseOnHover={true}
        pauseOnClick={true}
        speed={65}
        direction="right"
      >
        <div
          className={styles.items}
        >
            {props.heading.map((heading, _key) => (
              <p style={paragraph} key={_key}> {heading} </p>
            ))}
        </div>
      </Marquee>
    </div>
    </Link>
  );
}
