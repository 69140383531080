import React from "react";
import styles from "./imageGrid.module.css";
import { ContainerComponent } from "../container";

import { imageUrlFor } from "../../lib/image-url";
import { buildImageObj } from "../../lib/helpers";

function ImageGrid(props) {
  /*
  const images = props.images.map((i) => {
    return (
      <img
        src={imageUrlFor(buildImageObj(i))
          .width(500)
          .url()}
        alt={i.alt}
        key={i._id}
        style={{
          padding: "5px",
          backgroundColor: i.asset.metadata.palette.dominant.background,
        }}
        title={i.caption}
      />
    );
  });
*/
  const imagesWithCaption = props.images.map((i) => {
    return (
      <figure style={{ margin: (props.margin && props.margin) || "0"}}>
        <img
          src={imageUrlFor(buildImageObj(i))
            .width(500)
            /*.height(300)*/
            .url()}
          alt={i.alt}
          key={i._id}
          loading={"lazy"}
          style={{
            padding: "35px",
            borderRadius: (props.borderradius && props.borderradius) || "0px",
            backgroundColor: i.asset.metadata.palette.dominant.background,
          }}
        />
        <figcaption>{i.caption}</figcaption>
      </figure>
    );
  });

  return (
    <ContainerComponent>
      <div>
        <section className={styles.wrapper}>
          <h2>{props.heading}</h2>
          <div style={{ gap: (props.gridgap && props.gridgap) || "15px",  }} className={styles.grid}>{imagesWithCaption}</div>
        </section>
      </div>
    </ContainerComponent>
  );
}

export default ImageGrid;
