import React, { useState } from 'react'
import BlockContent from "../sanity/block-content";
import { ContainerComponent } from "../container";
import Accordion from '../accordion'

import styles from './accordions.module.css';

const Accordions = ( props ) => {
  const { heading, title, items, tagline } = props

  const [allOpen, setAllOpen] = useState(false)
  const [opened, setOpened] = useState(
    items.map((i) => ({ id: i._key, open: false }))
  )

  const onChange = (id, status) => {
    const newOpened = opened.map((accordion) =>
      accordion.id === id ? { ...accordion, open: status } : accordion
    )

    setOpened(newOpened)

    if (newOpened.every((accordion) => accordion.open === true)) {
      setAllOpen(true)
    }

    if (newOpened.every((accordion) => accordion.open === false)) {
      setAllOpen(false)
    }
  }

  return (
    <section>
      <ContainerComponent>
        <div>
          <h2>
            {heading}
          </h2>
          <div className={styles.accordionTagline}>
            {tagline}
            <button className={styles.accordionBtn} onClick={() => setAllOpen(!allOpen)}>
              {allOpen ? 'Trekk sammen' : 'Utvid'} alle
            </button>
          </div>
          <div>
            {items.map((accordion, key) => {
              return (
                <Accordion
                  key={key}
                  title={accordion.title}
                  id={accordion._key}
                  toggle={allOpen}
                  onChange={onChange}
                >
                  <BlockContent
                    renderContainerOnSingleChild
                    className="rc"
                    blocks={accordion.content}
                  />
                </Accordion>
              )
            })}
          </div>
        </div>
      </ContainerComponent>
    </section>
  )
}

export default Accordions;
